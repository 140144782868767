
import router from "@/router";
import store from "@/store";
import {defineComponent, onMounted, ref} from "vue";
import FormLoading from "@/components/layout/FormLoading.vue";
import BaseService from "@/services/admin/BaseService";
import User from "@/interfaces/User";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Redacao from "@/interfaces/Redacao";

interface Form {
  redacao_id?: string;
  aluno_id?: string;
  imagem?: string;
}

const baseForm: Form = {
  redacao_id: "",
  aluno_id: "",
  imagem: "",
};
export default defineComponent({
  components: { FormLoading },
  setup() {
    const form = ref<Form>(baseForm);
    const $q = useQuasar();
    const sendRedacaoShow = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const alunos = ref<User[]>([]);
    const filteredAlunos = ref<User[]>([]); // Tipo explicitamente definido
    const filteredRedacoes = ref<Redacao[]>([]);
    const redacoes = ref<Redacao[]>([]);
    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    const submeterRedacao = (id: string): void => {
      loading.value = true;
      const formData = new FormData();
      formData.append(
        "anexo_aluno",
        form.value.imagem ? form.value.imagem : ""
      );
      formData.append("aluno_id", form.value.aluno_id ? form.value.aluno_id : "");
      formData.append("redacao_id", form.value.redacao_id ? form.value.redacao_id : "");
      formData.append("resposta", '');
      BaseService.store(`redacoes/send-redacao-prova`, formData)
        .then((res) => {
          showNotify({
            type: "positive",
            message: "Redação enviada com sucesso",
          });
          form.value = baseForm;
          sendRedacaoShow.value = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              showNotify({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            showNotify({
              type: "negative",
              message: "Erro ao enviar resposta",
            });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const getAlunos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        BaseService.list("divisoes/alunos/" + store.getters.divisaoId)
          .then((res) => {
            alunos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("alunos")
          .then((res) => {
            alunos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getRedacoes = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        BaseService.list("divisoes/redacoes/" + store.getters.divisaoId)
          .then((res) => {
            redacoes.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("redacoes")
          .then((res) => {
            redacoes.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };
    const filterAlunos = (val: string, update: any) => {
      update(() => {
        filteredAlunos.value = val
          ? alunos.value.filter(aluno =>
            aluno.nome.toLowerCase().includes(val.toLowerCase())
          )
          : alunos.value;
      });
    };


    const filterRedacoes = (val: string, update: any) => {
      update(() => {
        filteredRedacoes.value = val ? redacoes.value.filter(redacao => redacao.titulo.toLowerCase().includes(val.toLowerCase())) : redacoes.value;
      });
    };

    onMounted(() => {
      getAlunos();
      getRedacoes();
    });
    return { goTo, store, sendRedacaoShow, loading, alunos, form, redacoes, filteredAlunos, filteredRedacoes, filterAlunos, filterRedacoes, submeterRedacao };
  },
});
